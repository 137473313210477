<template>
  <div id="adding-items" class="popup">
    <div class="popup-overlay" @click="close"></div>

    <div class="popup-container">
      <div class="svg close" @click="close">
        <svg><use xlink:href="#svg-close"/></svg>
      </div>

      <div class="popup-content">
        <template v-if="state === 'token'">
          <h3>Add NFT</h3>
          <p>Select the NFT you want to add</p>
        </template>
        <template v-if="state === 'collection'">
          <h3>Move NFT to Collection</h3>
          <p>Select the Collection</p>
        </template>

        <div class="form">
          <div class="fel">
            <input type="text" placeholder="Search" v-model.trim="search" />
          </div>

          <div class="list">
            <div
              class="item"
              :class="{ checked: index === checked }"
              v-for="(item, index) in items"
              :key="index"
              @click="selectItem(index)"
            >
              <TokenContentType
                :file-url="item.fileUrl"
                :token-type="item.type ? item.type : 'IMAGE'"
                :show-audio-player="false"
                :preview-url="item.previewUrl"
                :wrapper="'image'"
              />
              <div class="title">{{ item.title }}</div>
            </div>
          </div>
          <ScrollLoader
            :loader-method="appointLoaderMethod"
            :loader-disable="disableLoading"
          />

          <button
            class="button green"
            v-if="state === 'token'"
            @click="add"
            :disabled="!checked"
          >Add</button>

          <button
            class="button green"
            v-if="state === 'collection'"
            @click="add"
            :disabled="!checked"
          >Move</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import closeModalMixin from "@/mixins/closeModalMixin";
import api from "@/api/api";
import TokenContentType from "@/components/common/TokenContentType";
import { mapGetters } from "vuex";
import DebounceMixin from "@/mixins/debounceMixin";
import ResetPrevLoadingMixin from "@/mixins/resetPrevLoadingMixin";
import getUserNFTCollectionsMixin from "@/mixins/collection/getUserNFTCollectionsMixin";

export default {
  name: "ListModal",
  components: { TokenContentType },
  mixins: [
    closeModalMixin,
    getUserNFTCollectionsMixin,
    DebounceMixin,
    ResetPrevLoadingMixin,
  ],
  props: {
    state: {
      type: String,
      required: false,
      default: () => "token",
    },
  },
  data: () => ({
    checked: null,
    search: null,
    debouncedSearch: null,
  }),
  computed: {
    ...mapGetters({
      getId: "user/getId",
    }),
    appointLoaderMethod() {
      this.resetPrevLoading();
      switch (this.state) {
        case "token":
          if (this.search) {
            return this.debouncedSearch;
          }
          return this.getOwnNft;
        case "collection":
          if (this.search) {
            return this.debouncedSearch;
          }
          return this.getUserNftCollections;
        default:
          return null;
      }
    },
  },
  methods: {
    add() {
      this.$emit(
        "add",
        this.state === "token"
          ? this.items[this.checked]
          : this.items[this.checked].id
      );
    },
    selectItem(index) {
      this.checked = index;
    },
    async getOwnNft() {
      try {
        const response = await api.getOwnNft({
          page: this.page++,
          size: this.size,
          userId: this.getId,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      }
    },
    async ownNftSearch() {
      try {
        const response = await api.ownNftSearch({
          page: this.page++,
          size: this.size,
          search: this.search,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      }
    },
  },
  mounted() {
    this.debouncedSearch = this.debounce(
      this.state === "token" ? this.ownNftSearch : this.ownCollectionSearch,
      100
    );
  },
};
</script>
