import setLoadingMixin from "@/mixins/setLoadingMixin";
import collectionInfoMixin from "@/mixins/collectionInfoMixin";
import api from "@/api/api";

export default {
  mixins: [setLoadingMixin, collectionInfoMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    collection: null,
    items: [],
    page: 0,
    size: 12,
    disableLoading: false,
  }),
  methods: {
    async getNftCollectionById() {
      try {
        this.collection = await api.getNftCollectionById({
          collectionId: this.id,
        });
      } catch {
        this.setLoading(false);
        await this.$router.push("/404");
      }
    },
    async getNftTokenByCollectionId() {
      try {
        this.loading = true;
        const response = await api.getNftTokenByCollectionId({
          collectionId: this.id,
        });
        this.items = [...this.items, ...response.content];
        this.disableLoading = response.content.length < this.size;
      } catch {
        this.disableLoading = true;
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    this.setLoading(true);
    await this.getNftCollectionById();
    this.setLoading(false);
  },
};
