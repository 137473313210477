<template>
  <div v-if="collection">
    <div id="intro">
      <div class="section">
        <h1>Edit Collection</h1>
      </div>
    </div>
    <!--[ #intro ]-->

    <div id="edit-collection">
      <div class="section top">
        <div class="flex">
          <div class="half">
            <div class="fel">
              <div class="fel-label">Title</div>
              <input type="text" name="title" v-model="collectionTitle" />
              <div class="fel-error"></div>
            </div>

            <div class="fel">
              <div class="fel-label">Description</div>
              <textarea
                name="description"
                v-model="collectionDescription"
              ></textarea>
              <div class="fel-error"></div>
            </div>
          </div>

          <div class="fel">
            <div class="fel-label">Cover</div>
            <div class="fel-file" @click="openFileInput('file')">
              <input type="file" ref="file" @change="setFile" />
              <img
                :src="secureUrl || newFileUrl || collectionImageUrl"
                :alt="collectionTitle"
              />
              <button type="button" class="round">Change Cover</button>
              <div class="fel-description">
                AVIF, JPG, PNG, SVG, WEBP. Max&nbsp;30MB.
              </div>
              <div class="fel-description">
                Recommended minimum image size requirements:
                1920&nbsp;×&nbsp;520
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section middle">
        <div class="flex">
          <TokenCard
            v-for="(item, index) in items"
            :key="index"
            :token="item"
            :mode="'edit'"
            @removeTokenFromCollection="removeTokenFromCollection($event, id)"
            @changeTokenCollection="changeTokenCollectionOpen"
          />

          <div class="item" @click="isTokenListModal = true">
            <div class="svg">
              <svg><use xlink:href="#svg-add" /></svg>
            </div>
          </div>
        </div>
        <ScrollLoader
          :loader-method="getNftTokenByCollectionId"
          :loader-disable="disableLoading"
          ><img width="200" height="200" src="@/assets/img/preloader.svg" />
        </ScrollLoader>
      </div>

      <div class="section bottom">
        <div class="flex">
          <button type="button" class="green" @click="updateNFTCollection">
            Save
          </button>
          <button type="button" @click="deleteCollection">
            Delete collection
          </button>
        </div>
      </div>
    </div>

    <ListModal
      :state="'collection'"
      @close="isCollectionListModal = false"
      v-if="isCollectionListModal"
      @add="changeTokenCollection"
    />
    <ListModal
      :state="'token'"
      @close="isTokenListModal = false"
      v-if="isTokenListModal"
      @add="addTokenToCollection"
    />
  </div>
</template>

<script>
import InitCollectionMixin from "@/mixins/collection/initCollectionMixin";
import OpenFileInputMixin from "@/mixins/openFileInputMixin";
import UploadCollectionImageMixin from "@/mixins/collection/uploadCollectionImageMixin";
import FileToBase64Mixin from "@/mixins/fileToBase64Mixin";
import api from "@/api/api";
import successes from "@/util/successes";
import TokenCard from "@/components/common/TokenCard";
import ListModal from "@/components/modals/ListModal";
import DeleteCollectionMixin from "@/mixins/collection/deleteCollectionMixin";
import RemoveTokenFromCollectionMixin from "@/mixins/collection/removeTokenFromCollectionMixin";
import useLinkMixin from "@/mixins/useLinkMixin";

export default {
  name: "EditCollection",
  components: {
    ListModal,
    TokenCard,
  },
  mixins: [
    InitCollectionMixin,
    OpenFileInputMixin,
    UploadCollectionImageMixin,
    FileToBase64Mixin,
    DeleteCollectionMixin,
    RemoveTokenFromCollectionMixin,
    useLinkMixin,
  ],
  data: () => ({
    newFileUrl: null,
    isCollectionListModal: false,
    isTokenListModal: false,
    tokenId: null,
  }),
  methods: {
    async addTokenToCollection(token) {
      try {
        this.setLoading(true);
        await api.addNFTTokenToCollection({
          tokenId: token.id,
          collectionId: this.id,
        });
        this.setSuccess(successes.TOKEN_ADDED_TO_COLLECTION);
        this.items.unshift(token);
        this.isTokenListModal = false;
      } catch (e) {
        this.setError(e.response.data.code);
      } finally {
        this.setLoading(false);
      }
    },
    changeTokenCollectionOpen(val) {
      this.isCollectionListModal = true;
      this.tokenId = val;
    },
    async changeTokenCollection(collectionId) {
      try {
        this.setLoading(true);
        await api.changeTokenCollection({
          tokenId: this.tokenId,
          newCollectionId: collectionId,
          oldCollectionId: this.id,
        });
        this.setSuccess(successes.TOKEN_COLLECTION_CHANGED);
        if (collectionId !== this.id) {
          this.items = this.items.filter((i) => i.id !== this.tokenId);
        }
        this.isCollectionListModal = false;
      } catch (e) {
        this.setError(e.response.data.code);
      } finally {
        this.tokenId = null;
        this.setLoading(false);
      }
    },
    async setFile() {
      const file = this.$refs.file.files[0];
      this.newFileUrl = await this.getBase64(file);
    },
    async updateNFTCollection() {
      try {
        this.setLoading(true);
        if (this.newFileUrl) {
          await this.uploadFile();
        }
        const body = {
          description: this.collectionDescription,
          fileId: this.publicId,
          fileUrl: this.secureUrl,
          title: this.collectionTitle,
        };
        const query = {
          collectionId: this.id,
        };
        await api.updateNfTCollection({
          body,
          query,
        });
        this.setLoading(false);
        await this.$router.push(this.collectionLink(this.id));
      } catch (e) {
        this.setError(e.response.data.code || e.response.data.error.message);
        this.setLoading(false);
      }
    },
  },
};
</script>
