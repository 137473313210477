import api from "@/api/api";
import successes from "@/util/successes";
import SetProcessingMixin from "@/mixins/setProcessingMixin";

export default {
  mixins: [SetProcessingMixin],
  methods: {
    async deleteCollection() {
      try {
        this.setLoading(true);
        await api.deleteNftCollection({
          collectionId: this.id,
        });
        this.setLoading(false);
        this.setSuccess(successes.COLLECTION_REMOVED);
        await this.$router.push({
          name: "Collections",
          query: {
            removed: this.id,
          },
        });
      } catch (e) {
        this.setError(e.response?.data.code);
        this.setLoading(false);
      }
    },
  },
};
